import { render, staticRenderFns } from "./MealFilterList.vue?vue&type=template&id=3c3f112a&"
import script from "./MealFilterList.vue?vue&type=script&lang=js&"
export * from "./MealFilterList.vue?vue&type=script&lang=js&"
import style0 from "./MealFilterList.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports